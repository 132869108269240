import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["newAnag", "anag", "newAnagModal"];
  static values = {
    anagDetailsUrl: String,
  };

  connect() {
    // let backdrop = document.querySelector(".modal-backdrop");
    // if (backdrop) {
    //   backdrop.remove();
    // }
    this.anagModal = new Modal(this.newAnagModalTarget);
    this.anagModal.show();
    this.element.addEventListener("hidden.bs.modal", (event) => {
      this.element.remove();
      // let backdrop = document.querySelector(".modal-backdrop");
      // if (backdrop) {
      //   backdrop.remove();
      // }
    });
  }

  showCreateAnagFields() {
    let backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.remove();
    }
    this.anagModal.show();
    this.element.addEventListener("hidden.bs.modal", (event) => {
      this.element.remove();
    });
  }
  hideCreateAnagFields() {
    this.anagModal.hide();
  }

  checkCompletion(event) {
    console.log("compl");
    if (event.detail.formSubmission.result.success) {
      console.log("complOK");
      this.anagModal.hide();
      let newEvent = new Event("change");
      let element = document.getElementById("select-anag-id");
      setTimeout(() => element.dispatchEvent(newEvent), 200);
    }
  }
}
