import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "vehicleOther",
    "vehicleOtherContainer",
    "vehicleOtherField",
    "activityOther",
    "activityOtherContainer",
    "activityOtherField",
    "detailOther",
    "detailOtherContainer",
    "detailOtherField",
  ];

  connect() {
    this.showHide();
  }

  showHide() {
    if (this.hasActivityOtherTarget && this.hasActivityOtherContainerTarget) {
      if (this.activityOtherTarget.checked) {
        this.activityOtherContainerTarget.classList.remove("d-none");
      } else {
        this.activityOtherContainerTarget.classList.add("d-none");
        this.activityOtherFieldTarget.value = "";
      }
    }
    if (this.hasDetailOtherTarget && this.hasDetailOtherContainerTarget) {
      if (this.detailOtherTarget.checked) {
        this.detailOtherContainerTarget.classList.remove("d-none");
      } else {
        this.detailOtherContainerTarget.classList.add("d-none");
        this.detailOtherFieldTarget.value = "";
      }
    }
    if (this.hasVehicleOtherTarget && this.hasVehicleOtherContainerTarget) {
      if (this.vehicleOtherTarget.checked) {
        this.vehicleOtherContainerTarget.classList.remove("d-none");
      } else {
        this.vehicleOtherContainerTarget.classList.add("d-none");
        this.vehicleOtherFieldTarget.value = "";
      }
    }
  }
}
