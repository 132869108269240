import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { it } from "flatpickr/dist/l10n/it.js";

// Connects to data-controller="ts-select"
export default class extends Controller {
  connect() {
    let dates = JSON.parse(this.element.dataset.dates);
    this.control = flatpickr(this.element, {
      altInput: true,
      altFormat: "d/m/Y H:i",
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      locale: "it",
      time_24hr: true,
      onDayCreate: function (dObj, dStr, fp, dayElem) {
        // Utilize dayElem.dateObj, which is the corresponding Date
        let availability_index = dates
          .map((el) => Date.parse(new Date(el.date.split("-"))))
          .indexOf(Date.parse(dayElem.dateObj));
        if (availability_index !== -1) {
          console.log(dates[availability_index]);
          dayElem.innerHTML += `<div class="fs-6 mt-n2 mb-2 ps-2" style="line-height: 1em; text-align: left;"><b>${dates[availability_index].appointments}/${dates[availability_index].availability}</b><br>${dates[availability_index].confirmed} Confermati<br>${dates[availability_index].to_be_confirmed} Da confermare</div>`;
          if (dates[availability_index].remaining > 0) {
            if (dates[availability_index].appointments > 0) {
              dayElem.classList.add("bg-warning-soft");
            } else {
              dayElem.classList.add("bg-success-soft");
            }
          } else {
            dayElem.classList.add("bg-danger-soft");
          }
        } else {
          dayElem.classList.add("bg-light-soft");
        }
      },
      onReady(_, __, fp) {
        fp.calendarContainer.classList.add("availability-calendar");
      },
    });
  }
  disconnect() {
    this.control.destroy();
  }
}
