import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "newBrand",
    "brand",
    "model",
    "selectModelFields",
    "newBrandModal",
  ];

  connect() {
    this.brandModal = new Modal(this.newBrandModalTarget);
  }

  async createBrand(event) {
    let newBrand = this.newBrandTarget.value;
    let url = event.target.dataset.url;

    if (newBrand == "" || newBrand == null) {
      alert("ATTENZIONE: inserire un marchio");
    } else {
      const response = await post(url, {
        body: JSON.stringify({
          brand: newBrand,
          target: this.brandTarget.id,
        }),
        responseKind: "turbo-stream",
      });

      this.hideCreateBrandFields();
      let event = new Event("change");
      setTimeout(() => this.brandTarget.dispatchEvent(event), 200);
    }
  }

  showCreateBrandFields() {
    this.brandModal.show();
  }
  hideCreateBrandFields() {
    this.brandModal.hide();
  }
}
