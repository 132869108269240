import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "newExtent",
    "extent",
    "model",
    "selectModelFields",
    "newExtentModal",
  ];

  connect() {
    this.extentModal = new Modal(this.newExtentModalTarget);
  }

  async createExtent(event) {
    let newExtent = this.newExtentTarget.value;
    let url = event.target.dataset.url;

    if (newExtent == "" || newExtent == null) {
      alert("ATTENZIONE: inserire una entità");
    } else {
      const response = await post(url, {
        body: JSON.stringify({
          extent: newExtent,
          target: this.extentTarget.id,
        }),
        responseKind: "turbo-stream",
      });
      this.hideCreateExtentFields();
    }
  }

  showCreateExtentFields() {
    this.extentModal.show();
  }
  hideCreateExtentFields() {
    this.extentModal.hide();
  }
}
