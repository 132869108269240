import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "requestType",
    "endDate",
    "endDateContainer",
    "allDay",
    "timesContainer",
    "startTime",
    "endTime",
  ];

  connect() {
    console.log("user request form controller connected");
    this.showHide();
  }

  showHide() {
    if (this.hasRequestTypeTarget) {
      var requestType = this.requestTypeTarget.value;
      console.log(requestType);
      if (requestType == "paid_leave" || requestType == "sick_leave") {
        this.endDateContainerTarget.classList.remove("d-none");
        this.allDayTarget.value = true;
        this.timesContainerTarget.classList.add("d-none");
        this.startTimeTarget.value = "";
        this.endTimeTarget.value = "";
      } else if (
        requestType == "paid_time_off" ||
        requestType == "paid_time_off_permit"
      ) {
        this.endDateContainerTarget.classList.add("d-none");
        this.endDateTarget.value = "";
        this.allDayTarget.value = false;
        this.timesContainerTarget.classList.remove("d-none");
      } else {
        this.endDateContainerTarget.classList.add("d-none");
        this.endDateTarget.value = "";
        this.allDayTarget.value = false;
        this.timesContainerTarget.classList.add("d-none");
        this.startTimeTarget.value = "";
        this.endTimeTarget.value = "";
      }
    }
  }
}
