import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";
const _ = require("lodash");

export default class extends Controller {
  connect() {
    // console.log("connect");
    // this.element.addEventListener("keydown", this.handleChange);
  }
  handleChange(event) {
    if (event.key == ",") {
      // event.preventDefault();
      // event.stopPropagation();
    }
    // event.target.value = formatted;
  }
}
