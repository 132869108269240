import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "targa",
    "telaio",
    "submitButton",
    "technicianSubmitButton",
    "existingQuotations",
  ];

  connect() {
    if (this.hasTargaTarget && this.hasTelaioTarget) {
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = true;
      }
      if (this.hasTechnicianSubmitButtonTarget) {
        this.technicianSubmitButtonTarget.disabled = true;
      }
    }
  }

  checkButton() {
    if (this.hasTargaTarget && this.hasTelaioTarget) {
      let targa = this.targaTarget.value;
      let telaio = this.telaioTarget.value;
      if (targa != "" || telaio != "") {
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.disabled = false;
        }
        if (this.hasTechnicianSubmitButtonTarget) {
          if (this.hasExistingQuotationsTarget) {
            this.technicianSubmitButtonTarget.disabled = true;
            // setTimeout(() => {
            //   if (this.existingQuotationsTarget.innerHTML == "") {
            //     this.technicianSubmitButtonTarget.disabled = false;
            //   } else {
            //     this.technicianSubmitButtonTarget.disabled = true;
            //   }
            // }, 100);
            // setTimeout(() => {
            //   if (this.existingQuotationsTarget.innerHTML == "") {
            //     this.technicianSubmitButtonTarget.disabled = false;
            //   } else {
            //     this.technicianSubmitButtonTarget.disabled = true;
            //   }
            // }, 250);
            setTimeout(() => {
              if (this.existingQuotationsTarget.innerHTML == "") {
                this.technicianSubmitButtonTarget.disabled = false;
              } else {
                this.technicianSubmitButtonTarget.disabled = true;
              }
            }, 500);
            setTimeout(() => {
              if (this.existingQuotationsTarget.innerHTML == "") {
                this.technicianSubmitButtonTarget.disabled = false;
              } else {
                this.technicianSubmitButtonTarget.disabled = true;
              }
            }, 1000);
            setTimeout(() => {
              if (this.existingQuotationsTarget.innerHTML == "") {
                this.technicianSubmitButtonTarget.disabled = false;
              } else {
                this.technicianSubmitButtonTarget.disabled = true;
              }
            }, 1500);
            setTimeout(() => {
              if (this.existingQuotationsTarget.innerHTML == "") {
                this.technicianSubmitButtonTarget.disabled = false;
              } else {
                this.technicianSubmitButtonTarget.disabled = true;
              }
            }, 2500);
          } else {
            this.technicianSubmitButtonTarget.disabled = false;
          }
        }
      } else {
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.disabled = true;
        }
        if (this.hasTechnicianSubmitButtonTarget) {
          this.technicianSubmitButtonTarget.disabled = true;
        }
      }
    }
  }
}
