import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "newBrand",
    "newModel",
    "newModelSegment",
    "brand",
    "model",
    "createModelFields",
    "selectModelFields",
    "newModelModal",
    "newBrandModal",
    "targa",
    "telaio",
  ];

  connect() {
    if (this.hasNewModelModalTarget) {
      this.modelModal = new Modal(this.newModelModalTarget);
    }
    if (this.hasNewBrandModalTarget) {
      this.brandModal = new Modal(this.newBrandModalTarget);
    }
  }

  async checkExisting(event) {
    let targa = this.targaTarget.value;
    let url = event.target.dataset.url;

    if (targa != "" && targa != null) {
      const response = await post(url, {
        body: JSON.stringify({
          targa: targa,
          target: "existing-quotations-output",
        }),
        responseKind: "turbo-stream",
      });
    }
  }

  async checkExistingChassis(event) {
    let telaio = this.telaioTarget.value;
    let url = event.target.dataset.url;

    if (telaio != "" && telaio != null) {
      const response = await post(url, {
        body: JSON.stringify({
          telaio: telaio,
          target: "existing-quotations-output",
        }),
        responseKind: "turbo-stream",
      });
    }
  }

  async createBrand(event) {
    let newBrand = this.newBrandTarget.value;
    let url = event.target.dataset.url;

    if (newBrand == "" || newBrand == null) {
      alert("ATTENZIONE: inserire un marchio");
    } else {
      const response = await post(url, {
        body: JSON.stringify({
          brand: newBrand,
          target: this.brandTarget.id,
        }),
        responseKind: "turbo-stream",
      });
      let changeEvent = new Event("change");
      setTimeout(() => this.brandTarget.dispatchEvent(changeEvent), 100);
      // this.brandTarget.dispatchEvent(event);
      this.hideCreateBrandFields();
    }
  }

  async createModel(event) {
    let newModel = this.newModelTarget.value;
    let newModelSegment = null;
    if (this.hasNewModelSegmentTarget) {
      newModelSegment = this.newModelSegmentTarget.value;
    }
    let url = event.target.dataset.url;
    let brand_id = this.brandTarget.selectedOptions[0].value;

    if (brand_id == "" || brand_id == null) {
      alert("ATTENZIONE: selezionare un marchio");
    } else if (newModel == "" || newModel == null) {
      alert("ATTENZIONE: inserire un modello");
    } else {
      const response = await post(url, {
        body: JSON.stringify({
          model: newModel,
          brand_id: brand_id,
          segment_id: newModelSegment,
          target: this.modelTarget.id,
        }),
        responseKind: "turbo-stream",
      });
      let changeEvent = new Event("change");
      setTimeout(() => this.modelTarget.dispatchEvent(changeEvent), 100);
      this.hideCreateModelFields();
    }
  }

  showCreateModelFields() {
    this.modelModal.show();
  }
  hideCreateModelFields() {
    this.modelModal.hide();
  }

  showCreateBrandFields() {
    this.brandModal.show();
  }
  hideCreateBrandFields() {
    this.brandModal.hide();
  }
}
