import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="ts-select"
export default class extends Controller {
  connect() {}

  disconnect() {}

  updateParam(event) {
    window.location.href +=
      (window.location.href.indexOf("?") > -1 ? "&" : "?") +
      "per_page=" +
      event.target.value;
  }
}
