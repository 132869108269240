import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "checkbox",
    "actionsContainer",
    "operation",
    "value",
    "dataLavorazione",
    "statoLavorazione",
    "sede",
    "sconto",
    "anag",
    "humanResource",
    "numeroFattura",
    "dataFattura",
  ];

  connect() {}

  showHideActions() {
    let showActions = false;
    this.checkboxTargets.forEach((element) => {
      let value = element.checked;
      if (value) {
        showActions = true;
        element.closest("tr").classList.add("table-warning");
      } else {
        element.closest("tr").classList.remove("table-warning");
      }
    });
    if (showActions) {
      this.actionsContainerTargets.forEach((element) => {
        element.classList.remove("d-none");
      });
    } else {
      this.actionsContainerTargets.forEach((element) => {
        element.classList.add("d-none");
      });
    }
  }

  closeActions(e) {
    e.preventDefault();
    e.stopPropagation();
    this.checkboxTargets.forEach((element) => {
      element.checked = false;
    });
    this.showHideActions();
  }

  selectCategory(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log(e.target.dataset.category);
    let elements = document.querySelectorAll(
      "[data-category='" + e.target.dataset.category + "']"
    );
    this.checkboxTargets.forEach((element) => {
      element.checked = false;
    });
    elements.forEach((element) => {
      element.checked = true;
    });
    this.showHideActions();
  }

  inviaEmail(e) {
    e.preventDefault();
    this.operationTarget.value = "invia_email";
    this.valueTarget.value = "";
    this.element.requestSubmit();
  }
}
