import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "currentPrice",
    "targetPrice",
    "differenceValue",
    "differenceText",
  ];

  connect() {}
  calculate() {
    let currentPrice = parseFloat(this.currentPriceTarget.value);
    let targetPrice = parseFloat(this.targetPriceTarget.value);
    let difference = "";
    if (currentPrice && targetPrice) {
      difference = -(((currentPrice - targetPrice) / currentPrice) * 100);
      this.differenceTextTarget.innerHTML = difference.toFixed(2) + "%";
      this.differenceValueTarget.value = difference;
    } else {
      this.differenceTextTarget.innerHTML = "";
      this.differenceValueTarget.value = 0;
    }
  }
}
