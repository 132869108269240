import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { it } from "flatpickr/dist/l10n/it.js";

// Connects to data-controller="ts-select"
export default class extends Controller {
  connect() {
    let dates = JSON.parse(this.element.dataset.dates);
    this.control = flatpickr(this.element, {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      locale: "it",
      onDayCreate: function (dObj, dStr, fp, dayElem) {
        // Utilize dayElem.dateObj, which is the corresponding Date
        let availability_index = dates
          .map((el) => Date.parse(new Date(el[0].split("-"))))
          .indexOf(Date.parse(dayElem.dateObj));
        if (availability_index !== -1) {
          console.log(dates[availability_index]);
          if (dates[availability_index][1] > 0) {
            dayElem.classList.add("bg-success-soft");
          } else {
            dayElem.classList.add("bg-danger-soft");
          }
        }
      },
    });
  }
  disconnect() {
    this.control.destroy();
  }
}
