import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "isCustomer",
    "isSupplier",
    "customerCategory",
    "supplierCategory",
    "customerCategoryContainer",
    "supplierCategoryContainer",
  ];

  connect() {
    this.showHide();
  }

  showHide() {
    if (this.isCustomerTarget.checked) {
      this.customerCategoryContainerTarget.classList.remove("d-none");
    } else {
      this.customerCategoryContainerTarget.classList.add("d-none");
      this.customerCategoryTarget.value = "";
    }
    if (this.isSupplierTarget.checked) {
      this.supplierCategoryContainerTarget.classList.remove("d-none");
    } else {
      this.supplierCategoryContainerTarget.classList.add("d-none");
      this.supplierCategoryTarget.value = "";
    }
  }
}
