import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["linkedElement"];

  connect() {
    this.showHideFields();
  }

  showHideFields() {
    let containers = document.getElementsByClassName("custom-fields-container");
    if (containers.length > 0) {
      Array.from(containers).forEach((item) => {
        item.classList.add("d-none");
      });
    }
    if (this.hasLinkedElementTarget) {
      let element = document.getElementById(
        this.linkedElementTarget.value + "-fields"
      );
      if (element) {
        element.classList.remove("d-none");
      } else {
        let empty = document.getElementById("no-custom-fields");
        if (empty) {
          empty.classList.remove("d-none");
        }
      }
    }
  }
}
