import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "newPartName",
    "partName",
    "model",
    "selectModelFields",
    "newPartNameModal",
  ];

  connect() {
    this.partNameModal = new Modal(this.newPartNameModalTarget);
  }

  async createPartName(event) {
    let newPartName = this.newPartNameTarget.value;
    let url = event.target.dataset.url;

    if (newPartName == "" || newPartName == null) {
      alert("ATTENZIONE: inserire una parte");
    } else {
      const response = await post(url, {
        body: JSON.stringify({
          part_name: newPartName,
          target: this.partNameTarget.id,
        }),
        responseKind: "turbo-stream",
      });
      this.hideCreatePartNameFields();
    }
  }

  showCreatePartNameFields() {
    this.partNameModal.show();
  }
  hideCreatePartNameFields() {
    this.partNameModal.hide();
  }
}
