// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import * as bootstrap from "bootstrap";
import "@fortawesome/fontawesome-free/js/all.js";
import "trix";
import "@rails/actiontext";
window.Turbo = Turbo;

FontAwesome.config.mutateApproach = "sync";
