import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["select", "loading"];
  static values = {
    url: String,
    param: String,
  };

  connect() {
    if (this.hasSelectTarget && this.selectTarget.id === "") {
      this.selectTarget.id = Math.random().toString(36);
    }
  }

  async change(event) {
    if (this.hasSelectTarget) {
      let params = new URLSearchParams();
      params.append(this.paramValue, event.target.selectedOptions[0].value);
      params.append("target", this.selectTarget.id);

      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.remove("d-none");
      }

      await get(`${this.urlValue}?${params}`, {
        responseKind: "turbo-stream",
      });

      setTimeout(() => {
        if (this.selectTarget && this.selectTarget.tomselect) {
          this.selectTarget.tomselect.clear();
          this.selectTarget.tomselect.clearOptions();
          this.selectTarget.tomselect.sync();
        }
        if (this.hasLoadingTarget) {
          this.loadingTarget.classList.add("d-none");
        }
      }, 400);
    }
  }
}
