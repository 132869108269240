import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["textArea", "subject", "loading"];
  static values = {
    url: String,
    param: String,
  };

  connect() {
    if (this.hasTextAreaTarget && this.textAreaTarget.id === "") {
      this.textAreaTarget.id = Math.random().toString(36);
    }
    if (this.hasSubjectTarget && this.subjectTarget.id === "") {
      this.subjectTarget.id = Math.random().toString(36);
    }
    console.log("connected");
  }

  async change(event) {
    if (this.hasTextAreaTarget && this.hasSubjectTarget) {
      let params = new URLSearchParams();
      params.append(this.paramValue, event.target.selectedOptions[0].value);
      params.append("target", this.textAreaTarget.id);
      params.append("subject", this.subjectTarget.id);
      params.append("emailable_type", event.params.emailabletype);
      params.append("emailable_id", event.params.emailableid);

      await get(`${this.urlValue}?${params}`, {
        responseKind: "turbo-stream",
      });
    }
  }

  async batchChange(event) {
    if (this.hasTextAreaTarget && this.hasSubjectTarget) {
      let params = new URLSearchParams();
      params.append(this.paramValue, event.target.selectedOptions[0].value);
      params.append("target", this.textAreaTarget.id);
      params.append("subject", this.subjectTarget.id);
      params.append("emailable_type", event.params.emailabletype);

      await get(`${this.urlValue}?${params}`, {
        responseKind: "turbo-stream",
      });
    }
  }
}
