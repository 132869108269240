import { Controller } from "@hotwired/stimulus";
import { get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "quantita",
    "operation",
    "part",
    "specification",
    "specification2",
    "entity",
    "listPriceId",
    "specificationId",
    "specificationId2",
    "numeroBolli",
    "numeroBolliPiccoli",
    "numeroBolliGrandi",
    "prezzo",
    "differenza",
    "sconto",
    "importo",
    "calcoloDaNumero",
    "spettanzaFornitore",
    "spettanzaFornitoreOverride",
    "spettanzaFornitorePrezzo",
    "spettanzaTecnico",
    "spettanzaTecnicoPercentuale",
    "scontoFiliale",
    "tipologiaBolli",
    "tecnico",
    "differenzaPercentualeFornitore",
    "numeroBolliContainer",
    "numeroBolliPiccoliContainer",
    "numeroBolliGrandiContainer",
    "entitaContainer",
    "sede",
    "operazioneContainer",
    "campoLiberoContainer",
    "listinoAltro",
    "submitButton",
  ];
  static values = {
    partsUrl: String,
    specificationsUrl: String,
    entitiesUrl: String,
    param: String,
    getSpecificationUrl: String,
    getListPriceUrl: String,
    workPlaceDetailsUrl: String,
    humanResourceDetailsUrl: String,
    operationDetailsUrl: String,
    humanResourcesOptionsUrl: String,
  };

  connect() {
    if (this.partTarget.id === "") {
      this.partTarget.id = Math.random().toString(36);
    }
    // this.showHideBolli();
    this.tipologiaBolliChange();
    this.showHideCampoLibero();
  }

  showHideBolli(event) {
    let calcoloDaNumero = this.calcoloDaNumeroTarget.checked;
    if (calcoloDaNumero) {
      this.entityTarget.readOnly = true;
      this.numeroBolliTarget.readOnly = false;
    } else {
      this.numeroBolliTarget.readOnly = true;
      this.entityTarget.readOnly = false;
    }
    // this.numberChange();
  }

  showHideCampoLibero(event) {
    let listinoAltro = this.listinoAltroTarget.checked;
    if (listinoAltro) {
      this.operazioneContainerTarget.classList.add("d-none");
      this.campoLiberoContainerTarget.classList.remove("d-none");
      // this.spettanzaFornitoreTarget.value = 0;
      this.spettanzaFornitorePrezzoTarget.value = 0;
      this.updateTotals();
    } else {
      this.campoLiberoContainerTarget.classList.add("d-none");
      this.operazioneContainerTarget.classList.remove("d-none");
    }
    this.tipologiaBolliChange();
  }

  tipologiaBolliChange() {
    let tipologiaBolli = this.tipologiaBolliTarget.value;
    let listinoAltro = this.listinoAltroTarget.checked;
    if (listinoAltro) {
      this.numeroBolliPiccoliTarget.readOnly = false;
      this.numeroBolliGrandiTarget.readOnly = false;
      this.numeroBolliPiccoliTarget.removeAttribute("required");
      this.numeroBolliGrandiTarget.removeAttribute("required");
    } else if (tipologiaBolli == "piccoli") {
      this.numeroBolliPiccoliTarget.readOnly = false;
      this.numeroBolliGrandiTarget.readOnly = true;
      this.numeroBolliPiccoliTarget.setAttribute("required", "");
      this.numeroBolliGrandiTarget.removeAttribute("required");
      this.numeroBolliGrandiTarget.value = "";
    } else if (tipologiaBolli == "grandi") {
      this.numeroBolliGrandiTarget.readOnly = false;
      this.numeroBolliPiccoliTarget.readOnly = true;
      this.numeroBolliPiccoliTarget.removeAttribute("required");
      this.numeroBolliGrandiTarget.setAttribute("required", "");
      this.numeroBolliPiccoliTarget.value = "";
    } else if (tipologiaBolli == "mix") {
      this.numeroBolliGrandiTarget.readOnly = false;
      this.numeroBolliPiccoliTarget.readOnly = false;
      this.numeroBolliPiccoliTarget.setAttribute("required", "");
      this.numeroBolliGrandiTarget.setAttribute("required", "");
    } else {
      this.numeroBolliPiccoliTarget.readOnly = true;
      this.numeroBolliGrandiTarget.readOnly = true;
      this.numeroBolliPiccoliTarget.removeAttribute("required");
      this.numeroBolliGrandiTarget.removeAttribute("required");
      this.numeroBolliPiccoliTarget.value = "";
      this.numeroBolliGrandiTarget.value = "";
    }

    // this.sumChange();
  }

  // async supplierChange(event) {
  //   let params = new URLSearchParams();
  //   params.append("operation", event.target.selectedOptions[0].value);
  //   params.append("target", this.partTarget.id);

  //   const response = await get(`${this.partsUrlValue}?${params}`, {
  //     responseKind: "turbo-stream",
  //   });

  //   await response.text;
  //   this.partChange();
  // }

  async operationChange(event) {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true;
    }
    let params = new URLSearchParams();
    params.append("operation", event.target.selectedOptions[0].value);
    params.append("target", this.partTarget.id);

    const response = await get(`${this.partsUrlValue}?${params}`, {
      responseKind: "turbo-stream",
    });

    await response.text;
    this.partChange();

    let operation_params = new URLSearchParams();
    operation_params.append("operation", event.target.selectedOptions[0].value);
    const operationResponse = await get(
      `${this.operationDetailsUrlValue}.json?${operation_params}`,
      {
        responseKind: "json",
      }
    );
    let json = await operationResponse.json;
    if (json) {
      this.tipologiaBolliTarget.value = json.tipologia_bolli;
    }

    this.tipologiaBolliChange();

    // let changeEvent = new Event("change");
    // setTimeout(() => this.partTarget.dispatchEvent(changeEvent), 100);
  }

  async partChange(event) {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true;
    }
    let params = new URLSearchParams();
    params.append("operation", this.operationTarget.selectedOptions[0].value);
    params.append("part", this.partTarget.selectedOptions[0].value);
    params.append("target", this.specificationTarget.id);

    const response = await get(`${this.specificationsUrlValue}?${params}`, {
      responseKind: "turbo-stream",
    });

    let params2 = new URLSearchParams();
    params2.append("operation", this.operationTarget.selectedOptions[0].value);
    params2.append("part", this.partTarget.selectedOptions[0].value);
    params2.append("target", this.specification2Target.id);

    const response2 = await get(`${this.specificationsUrlValue}?${params2}`, {
      responseKind: "turbo-stream",
    });

    await response.text;
    await response2.text;

    let paramsEnt = new URLSearchParams();
    paramsEnt.append(
      "operation",
      this.operationTarget.selectedOptions[0].value
    );
    paramsEnt.append("part", this.partTarget.selectedOptions[0].value);
    paramsEnt.append("target", this.entityTarget.id);

    const responseEnt = await get(`${this.entitiesUrlValue}?${paramsEnt}`, {
      responseKind: "turbo-stream",
    });

    await responseEnt.text;

    this.specificationChange();
  }

  async specificationChange(event) {
    let params = new URLSearchParams();
    params.append("operation", this.operationTarget.selectedOptions[0].value);
    params.append("part", this.partTarget.selectedOptions[0].value);
    params.append(
      "specification",
      this.specificationTarget.selectedOptions[0].value
    );
    let params2 = new URLSearchParams();
    params2.append("operation", this.operationTarget.selectedOptions[0].value);
    params2.append("part", this.partTarget.selectedOptions[0].value);
    params2.append(
      "specification",
      this.specification2Target.selectedOptions[0].value
    );

    const response = await get(`${this.getSpecificationUrlValue}?${params}`, {
      responseKind: "json",
    });
    const response2 = await get(`${this.getSpecificationUrlValue}?${params2}`, {
      responseKind: "json",
    });

    let json = await response.json;
    this.specificationIdTarget.value = json.id;
    let json2 = await response2.json;
    this.specificationId2Target.value = json2.id;

    if (json.id || json2.id) {
      let difference1 = 0.0;
      let differenceSupplier1 = 0.0;
      let difference2 = 0.0;
      let differenceSupplier2 = 0.0;

      if (json.id) {
        difference1 = parseFloat(json.percentuale_su_prezzo);
        differenceSupplier1 = parseFloat(
          json.percentuale_su_spettanza_fornitore
        );
      }

      if (json2.id) {
        difference2 = parseFloat(json2.percentuale_su_prezzo);
        differenceSupplier2 = parseFloat(
          json2.percentuale_su_spettanza_fornitore
        );
      }

      if (json.id != json2.id) {
        this.differenzaTarget.value = difference1 + difference2;
        this.differenzaPercentualeFornitoreTarget.value =
          differenceSupplier1 + differenceSupplier2;
      } else {
        this.differenzaTarget.value = difference1;
        this.differenzaPercentualeFornitoreTarget.value = differenceSupplier1;
      }
    } else {
      this.differenzaTarget.value = "";
      this.differenzaPercentualeFornitoreTarget.value = "";
    }

    this.numberChange();
  }

  sumChange(event) {
    let numeroPiccoli = parseFloat(this.numeroBolliPiccoliTarget.value);
    let numeroGrandi = parseFloat(this.numeroBolliGrandiTarget.value);

    if (numeroPiccoli && numeroGrandi) {
      this.numeroBolliTarget.value = numeroPiccoli + numeroGrandi;
    } else if (numeroPiccoli) {
      this.numeroBolliTarget.value = numeroPiccoli;
    } else if (numeroGrandi) {
      this.numeroBolliTarget.value = numeroGrandi;
    } else {
      this.numeroBolliTarget.value = "";
    }
    this.numberChange();
  }

  async numberChange(event) {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true;
    }

    let valueSource = "number";
    if (event) {
      let target = event.target;
      if (target == this.entityTarget) {
        valueSource = "entity";
        this.calcoloDaNumeroTarget.checked = true;
      }
    } else {
      this.calcoloDaNumeroTarget.checked = false;
    }
    // console.log(this.calcoloDaNumeroTarget.checked);
    let params = new URLSearchParams();
    params.append("operation", this.operationTarget.selectedOptions[0].value);
    params.append("part", this.partTarget.selectedOptions[0].value);
    if (valueSource == "number") {
      params.append("number", this.numeroBolliTarget.value);
    } else {
      params.append("entity", this.entityTarget.value);
    }

    const response = await get(`${this.getListPriceUrlValue}?${params}`, {
      responseKind: "json",
    });

    let json = await response.json;
    this.listPriceIdTarget.value = json.id;

    if (json.id) {
      // console.log(json);
      this.prezzoTarget.value = json.prezzo;
      this.scontoFilialeTarget.value = json.sconto_filiale;
      this.spettanzaFornitorePrezzoTarget.value = json.spettanza_fornitore;
      if (valueSource == "number") {
        this.entityTarget.value = json.entita;
      } else {
        this.numeroBolliTarget.value = json.numero_bolli_da;
        let tipologiaBolli = this.tipologiaBolliTarget.value;
        if (tipologiaBolli == "piccoli") {
          this.numeroBolliPiccoliTarget.value = json.numero_bolli_da;
          this.numeroBolliGrandiTarget.value = "";
        } else if (tipologiaBolli == "grandi") {
          this.numeroBolliGrandiTarget.value = json.numero_bolli_da;
          this.numeroBolliPiccoliTarget.value = "";
        } else if (tipologiaBolli == "mix") {
          let piccoli = parseInt(json.numero_bolli_da / 2);
          let grandi = parseInt(json.numero_bolli_da) - piccoli;
          this.numeroBolliGrandiTarget.value = grandi;
          this.numeroBolliPiccoliTarget.value = piccoli;
        }
      }
    } else {
      this.prezzoTarget.value = "";
      this.spettanzaFornitorePrezzoTarget.value = "";
    }

    this.updateWorkPlace();
    // this.updateTotals();
  }

  async updateWorkPlace(event) {
    if (this.hasSedeTarget) {
      let workPlaceId = this.sedeTarget.selectedOptions[0].value;

      if (workPlaceId) {
        let params = new URLSearchParams();
        params.append(
          "work_place_anag_id",
          this.sedeTarget.selectedOptions[0].value
        );

        const response = await get(
          `${this.workPlaceDetailsUrlValue}?${params}`,
          {
            responseKind: "json",
          }
        );

        let json = await response.json;

        if (json) {
          if (this.scontoTarget.value == "") {
            this.scontoTarget.value = json.sconto_percentuale;
          }
        } else {
          this.scontoTarget.value = "";
        }
      }
    }

    this.updateTotals();
  }

  async updateHumanResource(event) {
    let params = new URLSearchParams();
    let humanResourceId = this.tecnicoTarget.selectedOptions[0].value;
    let json = {};
    if (humanResourceId) {
      params.append("human_resource_id", humanResourceId);

      const response = await get(
        `${this.humanResourceDetailsUrlValue}?${params}`,
        {
          responseKind: "json",
        }
      );
      json = await response.json;
    } else {
      json = false;
    }

    if (json) {
      this.spettanzaTecnicoPercentualeTarget.value =
        json.percentuale_compenso_tecnico;
    } else {
      this.spettanzaTecnicoPercentualeTarget.value = "";
    }

    this.updateTotals();
  }

  updateTotals() {
    let quantita = parseFloat(this.quantitaTarget.value);
    let prezzo = parseFloat(this.prezzoTarget.value);
    let spettanza = parseFloat(this.spettanzaFornitorePrezzoTarget.value);
    let spettanzaOverride = parseFloat(
      this.spettanzaFornitoreOverrideTarget.value
    );
    let compensoTecnicoPercentuale = parseFloat(
      this.spettanzaTecnicoPercentualeTarget.value
    );
    let differenza = parseFloat(this.differenzaTarget.value);
    let sconto = parseFloat(this.scontoTarget.value);
    let differenzaSpettanza = parseFloat(
      this.differenzaPercentualeFornitoreTarget.value
    );
    let scontoFiliale = this.scontoFilialeTarget.value;
    let importo;
    let spettanza_fornitore;
    let spettanzaRiferimento;

    if (scontoFiliale == false || scontoFiliale == "false") {
      this.scontoTarget.value = "";
      sconto = 0;
    }

    if (prezzo) {
      if (differenza) {
        importo = prezzo + prezzo * (differenza / 100.0);
      } else {
        importo = prezzo;
      }
      if (sconto) {
        importo = importo + importo * (sconto / 100.0);
      }
    }

    if (spettanza || spettanzaOverride) {
      if (spettanza) {
        if (differenzaSpettanza) {
          spettanza_fornitore =
            spettanza + spettanza * (differenzaSpettanza / 100.0);
        } else {
          spettanza_fornitore = spettanza;
        }
      }
      if (spettanzaOverride) {
        spettanzaRiferimento = spettanzaOverride;
      } else {
        spettanzaRiferimento = spettanza_fornitore;
      }
      if (compensoTecnicoPercentuale) {
        let compenso_tecnico =
          spettanzaRiferimento * (compensoTecnicoPercentuale / 100.0);
        this.spettanzaTecnicoTarget.value = (
          compenso_tecnico * quantita
        ).toFixed(2);
      }
    } else {
      this.spettanzaTecnicoTarget.value = 0;
    }

    this.importoTarget.value = (importo * quantita).toFixed(2);
    this.spettanzaFornitoreTarget.value = (
      spettanza_fornitore * quantita
    ).toFixed(2);

    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false;
    }
    this.updateParent();
  }

  updateParent() {
    let parentController =
      this.getParentControllerForIdentifier("quotation-form");
    if (parentController) {
      parentController.updateTotals();
    } else {
      // console.log("controller not found");
    }
  }

  getParentControllerForIdentifier(identifier) {
    return this.application.controllers.find((controller) => {
      return (
        controller.context.identifier === identifier &&
        controller.element.contains(this.element)
      );
    });
  }
}
